import React from 'react'
import { Layout} from '../components/layout'
import {RoundCard} from '../components/roundcardish'

const PersonalPage = () => {
    return(
        <Layout title={"Austin Loza - Me"}>
            <div className="flex space-x-10 p-10 font-mono">

                <RoundCard>
                    <h2 className="text-3xl">About Me</h2>
                    <p>
                        I'm 24 and can do cool stuff with code.
                    </p>
                </RoundCard>

                <RoundCard>
                    <h2 className="text-3xl">Games I'm Playing</h2>
                    <ul className="list-disc px-7">
                        <li>Star Wars Battlefront 2</li>
                        <li>Minecraft</li>
                    </ul>
                </RoundCard>

                <RoundCard>
                    <h2 className="text-3xl">Games I'm Waiting For</h2>
                    <ul className="list-disc px-7">
                        <li>Lego Star Wars - The Skywalker Saga</li>
                        <li>Halo Infinite</li>
                    </ul>
                </RoundCard>

                <RoundCard>
                    <h2 className="text-3xl">Web Dev Tech Stacks I Like</h2>
                    <ul className="list-disc px-7">
                        <li>Mongo/Express/React/Node (MERN)</li>
                        <li>JAM</li>
                        <li></li>
                    </ul>
                </RoundCard>
            </div>
        </Layout>
    )
}

export default PersonalPage